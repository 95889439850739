body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
textarea {
  font-family: 'Noto Sans', 'Trebuchet MS', sans-serif;
}
#root {
    display: flex;
    flex-direction: column;
    min-height:100vh;
}